@layer tailwind {
  @tailwind base;
}

@tailwind components;
@tailwind utilities;

/* 
* Thanks to @layer rule, tailwind base get lower "priority" 
* than mantine css rules even if they have same specificity.
* Thanks to this, we CAN use preflight reset from tailwind.
*/


:root {
  --brand-color-dark: #160B33;
  --text-sec-dark: #CDD0D5;
  --text-sec-light: #525866;

  --bg-purple-base: #6E3FF3;
  --bg-purple-light: #CAC2FF;
  --bg-purple-dark: #5A36BF;
  --bg-purple-darker: #2B1664;
  --bg-red-base: #DF1C41;
  --bg-red-light: #F8C9D2;
  --bg-red-dark: #AF1D38;
  --bg-red-darker: #710E21;
  --bg-yellow-base: #F2AE40;
  --bg-yellow-light: #FBDFB1;
  --bg-yellow-light-dark: #d99700;
  --bg-yellow-dark: #B47818;
  --bg-yellow-darker: #693D11;
  --bg-green-base: #38C793;
  --bg-green-light: #CBF5E5;
  --bg-green-dark: #2D9F75;
  --bg-green-darker: #176448;
  --text-neutral-400: #868C98;

  --score-10: #1FC16B;
  --score-20: #1DAF61;
  --score-30: #178C4E;
  --score-40: #335CFF;
  --score-50: #3559E9;
  --score-60: #2547D0;
  --score-70: #F6B51E;
  --score-80: #E6A819;
  --score-90: #FB3748;
  --score-100: #E93544;

  --score-dark-10: #178C4E;
  --score-dark-20: #178C4E;
  --score-dark-30: #178C4E;
  --score-dark-40: #2547D0;
  --score-dark-50: #2547D0;
  --score-dark-60: #2547D0;
  --score-dark-70: #E6A819;
  --score-dark-80: #E6A819;
  --score-dark-90: #E93544;
  --score-dark-100: #E93544;

}

.content-horizontal-padding {
  padding-left: calc(var(--mantine-spacing-lg) * 4);
  padding-right: calc(var(--mantine-spacing-lg) * 4);
}